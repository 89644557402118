import {request} from "./request";
export function getNewsData(page,limit) {
  return  request({
    params:{
      route:'news/news/getNewsList',
      information_id:'4',
      page,
      limit
    }
  })
};
export function getNewsDtl(cid) {
  return  request({
    params:{
      route:'news/news_detail/getNewsDetail',
      news_id:cid
    }
  })
};
