<template>
  <div class="page container-fluid" v-if="ofPages>1">
    <div class="pageBut" @click="pagePrev" :class="{prevStatus:!prevStatus}">
      <p class="pageText">上一页</p>
    </div>
    <div class="d-none d-md-flex d-lg-flex" v-for="item in ofPages">
      <p class="pageItem"
         @click="pageItem(item)"
         v-if="pageItemStatus(item)"
         :class="{activePageItem:item===page}">{{item}}</p>
      <p class="pageItemPoint" v-if="pagePointStatus(item)">...</p>
    </div>
    <div class="pageBut" @click="pageNext" :class="{prevStatus:!nextStatus}">
      <p class="pageText pageText2">下一页</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MainPager",
    data() {
      return {
        page: 1,
        active: 0,
      }
    },
    props: {
      ofPages: {
        type: Number,
        default: 1
      },
    },
    methods: {
      getActive() {
        this.$emit('pageActive', this.page);
      },
      pagePrev() {
        if (this.prevStatus) {
          this.page -= 1;
          this.getActive();
        }
      },
      pageNext() {
        if (this.nextStatus) {
          this.page += 1;
          this.getActive();
        }
      },
      pageItem(page) {
        this.page = page;
        this.getActive();
      },
      pageItemStatus(item) {
        if (this.ofPages > 6) {
          if (item <= 2 || item == this.ofPages || (item > this.page - 2 && item <= this.page) || (this.page == item)) {
            return true;
          }
        } else {
          return true;
        }
      },
      pagePointStatus(item) {
        if (item >= 2 && item != this.ofPages && item == this.page + 1 || (item == 3 && this.page > 4) && this.ofPages > 3) {
          return true;
        }
      }
    },
    computed: {
      prevStatus() {
        return this.page > 1;
      },
      nextStatus() {
        return this.page < this.ofPages;
      },
    },
    watch: {
      page(newValue) {
        this.$emit('pagerPage', newValue);
      }
    },
  }
</script>

<style scoped>
  .page {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .pageBut {
    display: flex;
    align-items: center;
    border: 1px solid var(--red);
    border-radius: 10px;
    color: var(--red);
    cursor: pointer;
  }

  .pageArrow {
    height: .4rem;
    width: .4rem;
  }

  .pageBut:active {
    background-color: var(--red);
    color: #fff;
  }

  .icon2 {
    display: none;
  }

  .pageBut:active .icon1 {
    display: none;
  }

  .pageBut:active .icon2 {
    display: block;
  }

  .pageItem {
    display: flex;
    align-items: center;
    padding: .2rem .35rem;
    margin: 0 .3rem;
    border: 1px solid var(--red);
    border-radius: 10px;
    color: var(--red);
    font-size: .4rem;
    cursor:pointer;
  }

  .activePageItem {
    background-color: var(--red);
    color: #fff;
  }

  .prevStatus, .prevStatus:active {
    border-color: #d9d9d9;
    background: none;
    color: #d9d9d9;
  }

  .pageBut {
    padding: .2rem .35rem;
    margin: 0 .3rem;
  }

  .pageText {
    font-size: .4rem;
    margin: 0 0 0 .2rem;
  }

  .pageText2 {
    margin: 0 .2rem 0 0;
  }

  .pageItemPoint {
    font-size: .6rem;
    color: var(--red);
  }

  @media screen and (max-width: 992px) {
    /*bootstrap 中屏幕*/

  }

  @media screen and (max-width: 768px) {
    /*bootstrap 小屏幕*/

  }

  @media screen and (min-width: 1179px) {
  }

</style>