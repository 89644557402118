<template>
  <div v-if="switchList.childList ">
    <div class="pcSwitch d-lg-block d-none">
      <div class="pcSwitchTitle">
        <p class="pcSwitchTitleC">{{switchList.text}}</p>
        <p class="pcSwitchTitleE">{{switchList.textE}}</p>
      </div>
      <p class="pcSwitchItem" @click="switchItem(index,item)" v-for="(item,index) in switchList.childList"
         :class="{activePcItem:index==activeIndex}">{{item.title || item.name}}</p>
    </div>
    <div class="wapSwitch d-block d-lg-none">
      <div class="wapSwitchTitle" @click="wapSwitchTitle">
        <p class="wapSwitchTitleValue">{{switchList.childList[activeIndex].title||switchList.childList[activeIndex].name}}</p>
        <p class="wapSwitchTitleIco">＋</p>
      </div>
      <div class="wapSwitchChild" :style="wapSwitchStyle">
        <p class="wapSwitchChildItem" v-for="(item,index) in switchList.childList" @click="switchItem(index,item)">{{item.title || item.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SwitchBar",
    props: {
      activeIndex: {
        type: Number,
        default: -1,
      },
      switchList: {
        type: Object,
        default() {
          return null
        }
      }
    },
    data(){
      return {
        showWapSwitch:false,
      }
    },
    methods: {
      switchItem(index,e) {
        if (e.path){
          this.$emit('switchItem', index,e.path);
        } else {
        this.$emit('switchItem', index);
        }
        this.showWapSwitch=false;
      },
      wapSwitchTitle(){
        this.showWapSwitch=!this.showWapSwitch;
      }
    },
    computed:{
      wapSwitchStyle(){
        let num=this.switchList.childList.length;
        if (this.showWapSwitch){
          return "height:"+(num*50)+'px;';
        }
        else {
          return 'height:0px;';
        }
      }
    },
  }
</script>

<style scoped>
  .wapSwitch{
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 3;
  }
  .wapSwitchChild{
    position: absolute;
    top: 50px;
    width: 100%;
    transition: all .15s linear;
    overflow: hidden;
    height: 0;
  }
  .wapSwitchChildItem{
    padding: 0 3%;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: .4rem;
    color: #666666;
    background-color: #fff;
    border-bottom: 1px solid #e2e2e2;
  }
  .wapSwitchTitle {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 50px;
    background-color: var(--red);
    padding: 0 3%;
    color: #fff;
  }

  .wapSwitchTitleValue {
    font-size: .4rem;
  }
  .wapSwitchTitleIco {
    font-size: .7rem;
  }

  .pcSwitchTitle {
    padding: .55rem .4rem .55rem 1.15rem;
    background-color: var(--red);
  }

  .pcSwitchTitleC {
    font-size: .55rem;
    color: #fff;
    margin-bottom: .25rem;
  }

  .pcSwitchTitleE {
    font-size: .3rem;
    color: #fff;
    text-transform: Uppercase;
  }

  .pcSwitchItem {
    padding: .55rem .4rem .55rem 1.15rem;
    border: 1px solid #ededed;
    font-size: .35rem;
    color: #3c3c3c;
    background-color: #f5f5f5;
    cursor: pointer;
  }

  .activePcItem {
    color: var(--red);
    border-right-color: #fff;
    border-left-color: var(--red);
    background-color: #fff;
  }
</style>