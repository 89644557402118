<template>
  <div>
    <img class="banner d-lg-block d-none" src="~assets/images/img/newsBanner.jpg"/>
    <div class="about container-fluid">
      <div class="row">
        <div class="col-12 col-lg-2" style="padding: 0">
          <switch-bar :switchList="switchList"
                      @switchItem="switchItem"
                      :activeIndex="Number(activeIndex)"/>
        </div>
        <div class="col-12 col-lg-9 aboutRight">
          <div class="rightTitle">
            <div class="rightTitleValue d-lg-block d-flex align-items-center">
              <p class="rightTitleValueC">{{showTitle}}</p>
              <p class="rightTitleValueE">{{showTitleE}}</p>
            </div>
            <div class="rightTitleLinks d-none d-lg-flex">
              <p>当前位置：</p>
              <router-link class="link" to="/home" tag="p">首页</router-link>
              >
              <!--刷新页面并且更新模块为第一块-->
              <router-link class="link" :to="{path:switchList.link,query:{index:0}}" tag="p">{{switchList.text}}
              </router-link>
              >
              <p class="linkLast">{{showTitle}}</p>
            </div>
          </div>
          <!--内容块-->
          <div class="WapBoxPadding" v-show="activeIndex==0">
            <!--置顶一块-->
            <router-link :to="{path:'/newsdetail/'+topNews.news_id,query:{index:activeIndex}}" tag="div" class="newsBox row" v-if="topNews">
              <div class="col-lg-5 col-12 newsBoxImgCoat">
                <img class="newsBoxImg" :src="topNews.thumb"/>
              </div>
              <div class="col-lg-7 col-12 newsBoxInfo" v-if="topNews">
                <p class="newsBoxTitle">{{topNews.title}}</p>
                <p class="newsBoxValue">{{topNews.description}}</p>
                <div class="newsBoxBottom">
                  <p class="newsBoxDate">{{topNews.date_added}}</p>
                  <p class="newsBoxBut">MORE →</p>
                </div>
              </div>
            </router-link>
            <!--列表块-->
            <div v-if="newsList && newsList.length>0">
              <router-link v-for="item in newsList" tag="div" :to="{path:'/newsdetail/'+item.news_id,query:{index:activeIndex}}"
                           class="newsBox row">
                <div class="col-3 d-none d-lg-block newsBoxImgCoat">
                  <img class="newsBoxImg" :src="item.thumb"/>
                </div>
                <div class="col-lg-9 col-12 newsBoxInfo">
                  <p class="newsBoxTitle">{{item.title}}</p>
                  <p class="newsBoxValue">{{item.description}}</p>
                  <div class="newsBoxBottom">
                    <p class="newsBoxDate">{{item.date_added}}</p>
                    <p class="newsBoxBut">MORE →</p>
                  </div>
                </div>
              </router-link>
            </div>
            <main-pager :of-pages="ofPages" @pagerPage="pagerPage"/>
          </div>
          <!--内容块 END-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import SwitchBar from "../../components/content/SwitchBar";
  import MainPager from "../../components/common/MainPager";
  import {getNewsData} from 'network/news';
  export default {
    name: "News",
    components: {MainPager, SwitchBar},
    data() {
      return {
        //顶部一篇新闻
        topNews:null,
        //一页的条数
        limit:5,
        total:null,
        //当前活跃的块下标
        activeIndex: 0,
        newsList: [],
      }
    },
    methods: {
      getNewsData(page,limit){
        getNewsData(page,limit).then(res=>{
          // 置顶一条新闻
          if (res.data.one_news && res.data.one_news[0]){
            this.topNews=res.data.one_news[0];
          }
          this.total=res.data.news_total;
          this.newsList=res.data.newses;
        })
      },

      switchItem(index) {
        if (index != this.$route.query.index) {
          this.$router.push({
            path: this.$route.path,
            query: {
              index: index
            }
          })
        }
      },
      //  分页发生变化时：
      pagerPage(page) {
        this.getNewsData(page,this.limit);
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        })
      }
    },
    computed: {
      ofPages(){
        if (this.total!=null){
          return Math.ceil(Number(this.total-1) / this.limit);
        }
      },
      switchList() {
        //从vuex中取出对应的数据 ==>store ==>index;
        return this.$store.state.navInfo[4];
      },
      // 当前活跃块的中文标题
      showTitle() {
        return this.switchList.childList[this.activeIndex].title;
      },
      // 当前活跃块的英文标题
      showTitleE() {
        return this.switchList.childList[this.activeIndex].titleE;
      },
    },
    created() {
      this.activeIndex = this.$route.query.index;
      this.getNewsData(1,this.limit);
    },
    watch: {
      '$route'() {
        this.activeIndex = this.$route.query.index;
      }
    }
  }
</script>

<style scoped>
  /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .6rem 3%;
      border-bottom: 1px solid #e2e2e2;
    }

    .WapBoxPadding {
      padding: 0 3%;
    }
  }

  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (min-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: .35rem;
      border-bottom: 1px solid #e2e2e2;
    }

    .rightTitleValueC {
      margin-bottom: .2rem;
    }
  }
</style>
<style scoped>
  .newsBox {
    margin-bottom: .75rem;
    cursor: pointer;
  }

  .newsBoxImgCoat {
    overflow: hidden;
  }

  .newsBoxImgCoat:hover .newsBoxImg {
    transform: scale(1.2, 1.2);
    transition: transform .6s;
  }

  .newsBoxImg {
    width: 100%;
    height: 100%;
    transition: all .3s linear;
  }

  .newsBoxInfo {
    background-color: #f5f5f5;
    padding: .6rem;
    height: auto;
  }

  .newsBoxTitle {
    font-size: .45rem;
    color: #595959;
    margin-bottom: .55rem;
  }

  .newsBoxValue {
    font-size: .35rem;
    color: #808080;
    margin-bottom: .5rem;
  }

  .newsBoxBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .newsBoxDate {
    font-size: .35rem;
    color: #737373;
  }

  .newsBoxBut {
    display: inline-block;
    padding: .1rem .8rem;
    border: 2px solid var(--red);
    font-size: .4rem;
    color: var(--red);
    border-radius: 30px;
    bottom: 0;
    cursor: pointer;
    transition: all .1s linear;
  }

  .newsBoxBut:hover {
    transition: bottom, box-shadow .1s;
  }

  .newsBox:hover .newsBoxTitle, .newsBox:hover .newsBoxValue, .newsBox:hover .newsBoxDate {
    color: #fff;
    transition: color .3s;
  }

  .newsBox:hover .newsBoxInfo {
    background-color: var(--red);
    transition: background-color .3s;
  }

  .newsBox:hover .newsBoxBut {
    border-color: #fff;
    background-color: #fff;
    transition: border, background-color .3s;
  }


  .banner {
    width: 100%;
    height: auto;
  }

  .about {
    padding: 1.65rem var(--switchMarginX);
  }

  .rightTitle {
    margin-bottom: .55rem;
  }

  .rightTitleValueC {
    font-size: .6rem;
    color: #474747;
    margin-right: .4rem;
  }

  .rightTitleValueE {
    font-size: .35rem;
    color: #b2b2b2;
    padding: 0;
  }

  .rightTitleLinks {
    display: flex;
    align-items: center;
    font-size: .35rem;
    color: #b2b2b2;
    cursor: pointer;
  }

  .rightTitleLinks .link:hover {
    color: var(--red);
    transition: color .3s;
  }

  .rightTitleLinks .linkLast {
    color: #737373;
  }
</style>